/*------------------------------------*\
    Page: warranty-registrations
\*------------------------------------*/
.view-webform-results-by-user {
  position: relative;
  width: 100%;
  padding: 2rem;
  margin-bottom: 4rem;
  border: 1px solid #ddd;
  border-radius: 3px;

  .view-filters {
    margin-bottom: 2rem;
  }

  table {
    font-size: 1.4rem;
    thead {
      tr {
        th {
          background-color: #f5f5f5;
          &.views-field-submitted {
            width: 20%;
          }
        }
      }
    }
  }
}
