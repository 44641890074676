/*------------------------------------*\
    Page: Thank You
\*------------------------------------*/

.thank-you{
   
    .node-page{
        position: relative;
        padding: 4rem 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8em;
        //min-height: 40vh;
    }
}
