/*------------------------------------*\
    Page: warranty-registration-form
\*------------------------------------*/
.warranty-registration-form {
  /*
  ======================================================
  : Page Content
  ======================================================
  */
  .region-content {
    position: relative;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    //min-height: 40vh;

    > .block-system {
      width: 100%;
    }

    footer {
      display: none;
    }
  }

  /*
  ======================================================
  : Webform
  ======================================================
  */
  .webform-client-form {
    // Header
    .webform-component--dura-seal-header,
    .webform-component--leather-guard-header {
      position: relative;
      width: 100%;
      padding-left: 50px;
      align-items: center;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
      text-align: left;

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 50px;
        height: 50px;
        font-size: 50px;
        line-height: 50px;
        color: $color-primary;
        @include fa-icon('\f058');
      }
    }

    .webform-component {
      h2 {
        @apply tw-text-5xl;
        margin-bottom: 0.8rem;
      }
    }

    .form-actions {
      button {
        @apply tw-text-3xl;
      }
    }

    .panel-body {
      &::before,
      &:after {
        display: none;
      }
      @include larger-than(767px) {
        @include grid-layout(3, 2.5rem);
        align-items: start;
        > .form-item {
          &:first-child {
            grid-column-start: 1;
          }
        }
      }
    }

    // Overwrite the panels
    .panel {
      background-color: transparent;
      border: none;
      border-radius: 0px;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .panel-heading {
      background-color: transparent;
      padding: 0 0 2.5rem 0;
      border-bottom: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      .panel-title {
        @apply tw-text-4xl;
        font-weight: 800;
      }
    }

    .panel-body {
      padding: 0;
    }

    .webform-component--vehicle-details {
      h2 {
        @apply tw-text-4xl;
        font-weight: 800;
      }
    }

    // End .webform-client-form
  }
}
