/*------------------------------------*\
   Header
\*------------------------------------*/

header {
  .theme-logo {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
  }

  .header-user-menu {
    display: flex;
    margin-left: auto;
    .menu {
      display: flex;
      font-size: 1.4rem;
    }
  }

  .region-top-banner {
    display: flex;
    justify-content: space-between;
  }
}
