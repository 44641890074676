/*------------------------------------*\
    User
\*------------------------------------*/
#user-profile-form {
  @apply tw-mb-8;
  padding: 2rem 2rem 6rem 2rem;
  border: 1px solid #eee;

  .form-item {
    @apply tw-relative;
    @apply tw-h-full;

    > label.control-label {
      @apply tw-h-full;
      width: 100% !important;
    }

    .form-control {
      text-indent: 0;
    }

    .form-control-feedback {
      @apply tw-hidden;
    }

    &.form-type-password-confirm {
      > .row {
        display: flex;
        flex-direction: column;
        > .form-item {
          width: 100%;
        }
        > .help-block {
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }
    }

    .progress {
      @apply tw-mb-0;
    }
  }

  #edit-mimemail {
    @apply tw-flex;
    @apply tw-flex-col;
    border: 1px solid #ddd;
    gap: 2rem;
    width: 100% !important;

    .form-item {
      flex: 0 0 auto;
      width: auto;
      input {
        text-indent: 0;
      }
    }

    legend {
      &.panel-heading {
        display: flex;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 3px;
      }
    }

    .panel-body {
      @apply tw-w-full;
      @apply tw-flex;
      @apply tw-flex-row;
      @apply tw-py-0;
    }

    .control-label {
      @apply tw-flex;
      @apply tw-flex-row;
      @apply tw-items-center;
      @apply tw-pl-0;

      input {
        width: 20px;
        height: 20px;
        &[type='checkbox'] {
          position: relative;
          margin-top: 0;
          margin-left: 0;
          margin-right: 1rem;
        }
      }
    }
  }

  #edit-timezone {
    @apply tw-hidden;
  }
}
