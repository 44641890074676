/*------------------------------------*\
    Footer
\*------------------------------------*/
.region-copyright {
  display: none;
}

footer {
  background-color: #0a1a2a;
  color: #f1f1f1;
  padding: 2rem 0;
  font-weight: 300;
  font-size: 1.6rem;

  .region-footer {
    @include grid-layout(5, 3rem);


    @include less-than($screen-md) {
      @include grid-layout(1, 0rem);
    }

    section {
      .block-title {
        font-weight: 500;
        margin-bottom: 3rem;
      }
    }

    .view-content {
      display: flex;
      flex-direction: column;
    }

    .views-row,
    li,
    > div {
      margin-bottom: 1rem;

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .social-media-link {
      width: 35px;
      height: 35px;
      border-radius: 100%;
      display: inline-block;
      background-color: #2b8ec6;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      color: #0A1A2A;
      transition: all 0.3s;
      font-size: 20px;

      @include less-than($screen-lg) {
        margin-right: 0;
      }

      &:hover {
        background-color: #fff;
        color: #0A1A2A;
      }
    }
  }
}
