/*------------------------------------*\
    Layout Styles
\*------------------------------------*/


/*
======================================================
: Body
======================================================
*/
body{
  &.warranty-registration{
    .main-content{
      .region-content{   
        display: flex;  
        align-items: center;
        align-content: center; 
        justify-content: center;
        margin-top: 8em;
        //min-height: 40vh;
      }
    }
  }
  &.user{
    .main-content{
      .region-content{   
        display: flex;  
        align-items: center;
        align-content: center; 
        justify-content: center;
        margin-top: 8em;
        //min-height: 40vh;   
      }
    }
  }
  
  #content-block{
    min-height: 75vh;  
  }

}


/*
======================================================
: tw-container
======================================================
*/

.tw-container {
  max-width: 1280px;

  @include less-than($screen-xl) {
    max-width: 1024px;
  }

  @include less-than($screen-lg) {
    max-width: 769px;
  }

  @include less-than($screen-md) {
    max-width: 640px;
  }

  @include less-than($screen-sm) {
    width: 100%;
    padding: 1rem 2rem;
  }
}

/*
======================================================
: Sidebar
======================================================
*/

.sidebar-first {
  .content {
    .sidebar {
      display: block;
    }

    .main-content {
      padding-left: 3rem;
    }
  }
}

main {
  position: relative;
  width: 100%;
  display: flex;
}

.content {
  min-height: 40rem;
  margin: 0;

  @include less-than($screen-sm) {
    margin: 0;
  }

  .breadcrumb {
    margin: 1rem 0 2rem;
  }

  .sidebar {
    display: none;
    .view-footer-category-links {display: none;}
  }
}
