/*------------------------------------*\
    Component: Breadcrumb
\*------------------------------------*/
.region-breadcrumbs {
  margin: 2rem 0;
  font-size: 1.4rem;
  font-weight: 300;

  .easy-breadcrumb_segment-separator {
    color: #999;
  }

  a.easy-breadcrumb_segment {
      @include link;
  }
}
