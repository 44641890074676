/*------------------------------------*\
    Global Page Styles
\*------------------------------------*/

/*
======================================================
: Global hidden elements
======================================================
*/
#sidebar-trigger {
  display: none;
}
.page_header {
  display: none;
}

/*
======================================================
: Inifinite scroll loader
======================================================
*/
.views_infinite_scroll-ajax-loader {
  margin: 4rem 0;
  display: flex;
  justify-content: center;
}

/*
======================================================
: Alert overwrite
======================================================
*/
.alert-dismissable {
  display: flex;
}

.alert {
  &.alert-danger {
    color: #fff;
  }
  .close {
    top: auto;
  }
}

.form-required {
  @apply tw-text-red-600;
}

.node-page {
  footer {
    display: none;
  }
}

/*
======================================================
: Global form : Register
======================================================
*/

form {
  &#-warranty-registration-check-form,
  &#warranty-registration-check-form {
    label {
      font-size: 2.5rem;
    }
  }

  .form-control {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  button,
  .btn-default,
  .btn {
    @apply tw-text-3xl;
    background-color: $color-primary;
    color: #fff;
    font-weight: 600;
    border-color: darken($color-primary, 20%);
    outline: none;

    &:focus,
    &:active,
    &:hover,
    &:visited {
      outline: none;
      color: #fff;
      background-color: darken($color-primary, 15%);
      border-color: darken($color-primary, 25%);
    }
  }
}

.webform-client-form {
  .webform-datepicker {
    display: flex;
    align-items: center;
    gap: 8px;

    .form-item {
      flex: 1;
      margin-bottom: 0;
      width: auto !important;

      .chosen-container-single {
        .chosen-single {
          border-radius: 0;
          box-shadow: none;
          border: none !important;
          background: transparent !important;
        }
      }
    }
  }
}
