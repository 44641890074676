/*------------------------------------*\
    Mixin: Search Bar (search input)
\*------------------------------------*/
@mixin searchbar {
  .views-exposed-widgets {
    position: relative;
    margin: 0;
    padding: 0;
  }

  .views-exposed-widget {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  input.form-text {
    width: 100%;
    height: 5rem;
    text-indent: 1rem;
    border: 2px solid #f1f1f1;
    border-radius: 4px;
    color: #333;
  }

  .views-submit-button {
    position: absolute;
    padding: 0;
    margin: 0;
    height: 100%;
    right: 1rem;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button.form-submit {
    background-image: url("/sites/all/themes/kodaweb-new/images/icons/search.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: transparent;
    width: 22px;
    height: 22px;
    margin: 0;
  }
}
