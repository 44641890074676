/*------------------------------------*\
    Basic Mixins
\*------------------------------------*/
@mixin link {
  color: #4d87ca;
  transition: color 0.3s;

  &:hover {
    color: lighten(#4d87ca, 20%);
  }
}

@mixin grid-layout($cols, $gap) {
  display: grid;
  grid-gap: $gap;
  grid-template-columns: repeat($cols, minmax(0, 1fr));
}

@mixin squareImgBox {
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  box-sizing: border-box;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin less-than($size) {
  @media screen and (max-width: $size) {
    @content;
  }
}

@mixin larger-than($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

@mixin fa-icon($code){
  content:$code;
  font-family: 'FontAwesome';
}
