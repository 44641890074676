/*------------------------------------*\
    Theme: admin
\*------------------------------------*/
.path-user {
  #contentblock {
    margin: 200px auto;
    width: 80%;
  }
}

.not-logged-in {
  .tabs--primary {
    display: none;
  }
}

/*
======================================================
: Editing Tabs
======================================================
*/
.tabs--primary {
  @apply tw-flex;
  @apply tw-flex-wrap;
  padding: 0.5rem 1rem;
  border: 1px dashed #eee;
  margin: 1rem 0;

  a {
    color: #333;
    margin-right: 1rem;
    font-size: 1.4rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

/*
======================================================
: User Login Page
======================================================
*/
.page-user {
  .region-content {
    form {
      margin: 0 auto;

      .help-block {
        font-size: 12px;
        display: none;
      }
      .form-item {
        margin-bottom: 10px;

        label {
          width: 100px;
          display: inline-block;
        }
        input {
          border: 1px solid #eee;
          height: 50px;
          width: 100%;
          text-indent: 20px;
          color: #555;
        }
      }

      button[type='submit'] {
        background: #333;
        color: #fff;
        border-radius: 3px;
        padding: 5px 20px;
        float: right;
      }
    }
  }
}

/*
======================================================
: masquerade
======================================================
*/
section#block-masquerade-masquerade {
  position: fixed;
  width: 100vw;
  bottom: 0;
  height: 4rem;
  background: #333;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;

  h2 {
    margin-bottom: 0;
    margin-right: 1rem;
  }

  form {
    > div,
    .container-inilne {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input {
      border-radius: 3px;
      background-color: #000;
      color: #c1c1c1;
      padding: 0.5rem;
      text-indent: 0.5rem;
    }

    button[type='submit'] {
      @include btn(#797979);
      display: inline-block;
      margin: 0 2rem;
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
    }
  }
}
