@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
/*------------------------------------*\
    Variables
\*------------------------------------*/
/*------------------------------------*\
    Base
\*------------------------------------*/
html {
  scroll-behavior: smooth;
  font-size: 62.5%; }

:focus {
  outline: none; }

/*------------------------------------*\
    Typography
\*------------------------------------*/
html {
  font-family: Arial, Helvetica, sans-serif; }

body {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-size: 1.6rem; }
  body h1 {
    @apply tw-text-6xl;
    font-weight: 800; }
  body h2 {
    @apply tw-text-5xl;
    font-weight: 800; }
  body h3 {
    @apply tw-text-4xl;
    font-weight: 800; }
  body h4 {
    @apply tw-text-3xl;
    font-weight: 800; }

p {
  font-size: 1.6rem;
  line-height: 1.5; }

/*------------------------------------*\
    Basic Mixins
\*------------------------------------*/
/*------------------------------------*\
    Mixin: Tabs
\*------------------------------------*/
/*------------------------------------*\
    Mixin: Search Bar (search input)
\*------------------------------------*/
/*------------------------------------*\
    Layout Styles
\*------------------------------------*/
/*
======================================================
: Body
======================================================
*/
body.warranty-registration .main-content .region-content {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 8em; }

body.user .main-content .region-content {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 8em; }

body #content-block {
  min-height: 75vh; }

/*
======================================================
: tw-container
======================================================
*/
.tw-container {
  max-width: 1280px; }
  @media screen and (max-width: 1281px) {
    .tw-container {
      max-width: 1024px; } }
  @media screen and (max-width: 1025px) {
    .tw-container {
      max-width: 769px; } }
  @media screen and (max-width: 769px) {
    .tw-container {
      max-width: 640px; } }
  @media screen and (max-width: 641px) {
    .tw-container {
      width: 100%;
      padding: 1rem 2rem; } }

/*
======================================================
: Sidebar
======================================================
*/
.sidebar-first .content .sidebar {
  display: block; }

.sidebar-first .content .main-content {
  padding-left: 3rem; }

main {
  position: relative;
  width: 100%;
  display: flex; }

.content {
  min-height: 40rem;
  margin: 0; }
  @media screen and (max-width: 641px) {
    .content {
      margin: 0; } }
  .content .breadcrumb {
    margin: 1rem 0 2rem; }
  .content .sidebar {
    display: none; }
    .content .sidebar .view-footer-category-links {
      display: none; }

/*------------------------------------*\
   Header
\*------------------------------------*/
header .theme-logo {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center; }

header .header-user-menu {
  display: flex;
  margin-left: auto; }
  header .header-user-menu .menu {
    display: flex;
    font-size: 1.4rem; }

header .region-top-banner {
  display: flex;
  justify-content: space-between; }

/*------------------------------------*\
    Footer
\*------------------------------------*/
.region-copyright {
  display: none; }

footer {
  background-color: #0a1a2a;
  color: #f1f1f1;
  padding: 2rem 0;
  font-weight: 300;
  font-size: 1.6rem; }
  footer .region-footer {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(5, minmax(0, 1fr)); }
    @media screen and (max-width: 769px) {
      footer .region-footer {
        display: grid;
        grid-gap: 0rem;
        grid-template-columns: repeat(1, minmax(0, 1fr)); } }
    footer .region-footer section .block-title {
      font-weight: 500;
      margin-bottom: 3rem; }
    footer .region-footer .view-content {
      display: flex;
      flex-direction: column; }
    footer .region-footer .views-row,
    footer .region-footer li,
    footer .region-footer > div {
      margin-bottom: 1rem; }
      footer .region-footer .views-row a:hover,
      footer .region-footer li a:hover,
      footer .region-footer > div a:hover {
        text-decoration: underline; }
    footer .region-footer .social-media-link {
      width: 35px;
      height: 35px;
      border-radius: 100%;
      display: inline-block;
      background-color: #2b8ec6;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      color: #0A1A2A;
      transition: all 0.3s;
      font-size: 20px; }
      @media screen and (max-width: 1025px) {
        footer .region-footer .social-media-link {
          margin-right: 0; } }
      footer .region-footer .social-media-link:hover {
        background-color: #fff;
        color: #0A1A2A; }

/*------------------------------------*\
    Components: alert
\*------------------------------------*/
.alert {
  position: relative;
  background: #4d87ca;
  border-radius: 4px;
  padding: 1rem;
  color: #fff;
  font-size: 300;
  display: flex;
  align-items: center;
  margin: 1rem 0; }
  .alert a.close {
    position: absolute;
    right: 1rem;
    font-size: 2rem;
    transition: all 0.3s; }
    .alert a.close:hover {
      color: #000; }
  .alert.alert-success {
    background-color: #86bc86; }
  .alert.alert-danger {
    background-color: #e53e3e; }
  .alert.alert-warning {
    background-color: #eebe76; }

/*------------------------------------*\
    Component: Breadcrumb
\*------------------------------------*/
.region-breadcrumbs {
  margin: 2rem 0;
  font-size: 1.4rem;
  font-weight: 300; }
  .region-breadcrumbs .easy-breadcrumb_segment-separator {
    color: #999; }
  .region-breadcrumbs a.easy-breadcrumb_segment {
    color: #4d87ca;
    transition: color 0.3s; }
    .region-breadcrumbs a.easy-breadcrumb_segment:hover {
      color: #9cbce1; }

/*------------------------------------*\
    Component: Burger Menu
\*------------------------------------*/
.hamburger-menu {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }

.hamburger-menu:hover {
  opacity: 0.7; }

.hamburger.is-active:hover {
  opacity: 0.7; }

.hamburger-menu.is-active .hamburger-inner,
.hamburger-menu.is-active .hamburger-inner::before,
.hamburger-menu.is-active .hamburger-inner::after {
  background-color: #000; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block; }

.hamburger-inner::before {
  top: -10px; }

.hamburger-inner::after {
  bottom: -10px; }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*------------------------------------*\
    Component: Button
\*------------------------------------*/
/*------------------------------------*\
    Component: Mobile Header
\*------------------------------------*/
.mobile_header {
  display: none;
  width: 100vw;
  height: 7rem;
  padding: 1rem;
  position: fixed;
  top: 0;
  z-index: 99;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 4px #eee; }
  @media screen and (max-width: 980px) {
    .mobile_header {
      display: flex; } }
  .mobile_header img {
    margin: 1rem;
    height: 3rem; }
  .mobile_header .hamburger-menu:focus {
    outline: none; }

/*------------------------------------*\
    Component: Mobile Menu
\*------------------------------------*/
#mmenu_left .block-header-logo {
  display: none; }

#mmenu_left .mm-list li {
  border: none; }

#mmenu_left .mm-list span,
#mmenu_left .mm-list a {
  padding: 0; }

#mmenu_left ul.mm-panel {
  padding-left: 2rem;
  padding-right: 2rem; }

#mmenu_left .user-bar {
  margin-top: 2rem;
  display: flex;
  flex-direction: column; }
  #mmenu_left .user-bar ul {
    width: 100%;
    display: flex;
    justify-content: space-around; }
    #mmenu_left .user-bar ul.user-login {
      justify-content: center; }
      #mmenu_left .user-bar ul.user-login li {
        margin-right: 1rem; }
    #mmenu_left .user-bar ul img {
      width: 20px;
      height: 20px;
      margin-right: 1rem; }
    #mmenu_left .user-bar ul a {
      display: flex; }

#mmenu_left .block-header-search .views-exposed-widgets {
  position: relative;
  margin: 0;
  padding: 0; }

#mmenu_left .block-header-search .views-exposed-widget {
  padding: 0;
  margin: 0;
  width: 100%; }

#mmenu_left .block-header-search input.form-text {
  width: 100%;
  height: 5rem;
  text-indent: 1rem;
  border: 2px solid #f1f1f1;
  border-radius: 4px;
  color: #333; }

#mmenu_left .block-header-search .views-submit-button {
  position: absolute;
  padding: 0;
  margin: 0;
  height: 100%;
  right: 1rem;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center; }

#mmenu_left .block-header-search button.form-submit {
  background-image: url("/sites/all/themes/kodaweb-new/images/icons/search.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: transparent;
  width: 22px;
  height: 22px;
  margin: 0; }

#mmenu_left .block-header-search input {
  width: 100% !important; }

#mmenu_left .block-uc-cart {
  margin: 2rem 0; }
  #mmenu_left .block-uc-cart .cart-block-title-bar,
  #mmenu_left .block-uc-cart p.cart-block-items {
    display: none; }

#mmenu_left .top-banner-menu li {
  border-bottom: 1px solid #25282f;
  border-top: 1px solid #444141; }
  #mmenu_left .top-banner-menu li a {
    padding: 1rem 0; }

/*------------------------------------*\
    Component: Slick Dots
\*------------------------------------*/
/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: "";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #4d87ca;
  border-radius: 100%; }

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black; }

/*------------------------------------*\
    Theme: admin
\*------------------------------------*/
.path-user #contentblock {
  margin: 200px auto;
  width: 80%; }

.not-logged-in .tabs--primary {
  display: none; }

/*
======================================================
: Editing Tabs
======================================================
*/
.tabs--primary {
  @apply tw-flex;
  @apply tw-flex-wrap;
  padding: 0.5rem 1rem;
  border: 1px dashed #eee;
  margin: 1rem 0; }
  .tabs--primary a {
    color: #333;
    margin-right: 1rem;
    font-size: 1.4rem; }
    .tabs--primary a:hover {
      text-decoration: underline; }

/*
======================================================
: User Login Page
======================================================
*/
.page-user .region-content form {
  margin: 0 auto; }
  .page-user .region-content form .help-block {
    font-size: 12px;
    display: none; }
  .page-user .region-content form .form-item {
    margin-bottom: 10px; }
    .page-user .region-content form .form-item label {
      width: 100px;
      display: inline-block; }
    .page-user .region-content form .form-item input {
      border: 1px solid #eee;
      height: 50px;
      width: 100%;
      text-indent: 20px;
      color: #555; }
  .page-user .region-content form button[type='submit'] {
    background: #333;
    color: #fff;
    border-radius: 3px;
    padding: 5px 20px;
    float: right; }

/*
======================================================
: masquerade
======================================================
*/
section#block-masquerade-masquerade {
  position: fixed;
  width: 100vw;
  bottom: 0;
  height: 4rem;
  background: #333;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem; }
  section#block-masquerade-masquerade h2 {
    margin-bottom: 0;
    margin-right: 1rem; }
  section#block-masquerade-masquerade form > div,
  section#block-masquerade-masquerade form .container-inilne {
    display: flex;
    justify-content: center;
    align-items: center; }
  section#block-masquerade-masquerade form input {
    border-radius: 3px;
    background-color: #000;
    color: #c1c1c1;
    padding: 0.5rem;
    text-indent: 0.5rem; }
  section#block-masquerade-masquerade form button[type='submit'] {
    background-color: #797979;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    padding: 0.8rem 2rem;
    transition: all 0.5s;
    font-size: 1.4rem;
    text-align: center;
    display: inline-block;
    margin: 0 2rem;
    padding: 0.5rem 1rem;
    font-size: 1.2rem; }
    section#block-masquerade-masquerade form button[type='submit']:hover, section#block-masquerade-masquerade form button[type='submit']:active {
      background-color: #939393; }

/*------------------------------------*\
    Global Page Styles
\*------------------------------------*/
/*
======================================================
: Global hidden elements
======================================================
*/
#sidebar-trigger {
  display: none; }

.page_header {
  display: none; }

/*
======================================================
: Inifinite scroll loader
======================================================
*/
.views_infinite_scroll-ajax-loader {
  margin: 4rem 0;
  display: flex;
  justify-content: center; }

/*
======================================================
: Alert overwrite
======================================================
*/
.alert-dismissable {
  display: flex; }

.alert.alert-danger {
  color: #fff; }

.alert .close {
  top: auto; }

.form-required {
  @apply tw-text-red-600; }

.node-page footer {
  display: none; }

/*
======================================================
: Global form : Register
======================================================
*/
form#-warranty-registration-check-form label, form#warranty-registration-check-form label {
  font-size: 2.5rem; }

form .form-control {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px; }

form button,
form .btn-default,
form .btn {
  @apply tw-text-3xl;
  background-color: #4d87ca;
  color: #fff;
  font-weight: 600;
  border-color: #295588;
  outline: none; }
  form button:focus, form button:active, form button:hover, form button:visited,
  form .btn-default:focus,
  form .btn-default:active,
  form .btn-default:hover,
  form .btn-default:visited,
  form .btn:focus,
  form .btn:active,
  form .btn:hover,
  form .btn:visited {
    outline: none;
    color: #fff;
    background-color: #2e619c;
    border-color: #234975; }

.webform-client-form .webform-datepicker {
  display: flex;
  align-items: center;
  gap: 8px; }
  .webform-client-form .webform-datepicker .form-item {
    flex: 1;
    margin-bottom: 0;
    width: auto !important; }
    .webform-client-form .webform-datepicker .form-item .chosen-container-single .chosen-single {
      border-radius: 0;
      box-shadow: none;
      border: none !important;
      background: transparent !important; }

/*------------------------------------*\
    Page: Home
\*------------------------------------*/
.front {
  /*
  ======================================================
  : Page Content
  ======================================================
  */ }

/*------------------------------------*\
    User
\*------------------------------------*/
#user-profile-form {
  @apply tw-mb-8;
  padding: 2rem 2rem 6rem 2rem;
  border: 1px solid #eee; }
  #user-profile-form .form-item {
    @apply tw-relative;
    @apply tw-h-full; }
    #user-profile-form .form-item > label.control-label {
      @apply tw-h-full;
      width: 100% !important; }
    #user-profile-form .form-item .form-control {
      text-indent: 0; }
    #user-profile-form .form-item .form-control-feedback {
      @apply tw-hidden; }
    #user-profile-form .form-item.form-type-password-confirm > .row {
      display: flex;
      flex-direction: column; }
      #user-profile-form .form-item.form-type-password-confirm > .row > .form-item {
        width: 100%; }
      #user-profile-form .form-item.form-type-password-confirm > .row > .help-block {
        padding-left: 2rem;
        padding-right: 2rem; }
    #user-profile-form .form-item .progress {
      @apply tw-mb-0; }
  #user-profile-form #edit-mimemail {
    @apply tw-flex;
    @apply tw-flex-col;
    border: 1px solid #ddd;
    gap: 2rem;
    width: 100% !important; }
    #user-profile-form #edit-mimemail .form-item {
      flex: 0 0 auto;
      width: auto; }
      #user-profile-form #edit-mimemail .form-item input {
        text-indent: 0; }
    #user-profile-form #edit-mimemail legend.panel-heading {
      display: flex;
      width: 100%;
      border: 1px solid #ddd;
      border-radius: 3px; }
    #user-profile-form #edit-mimemail .panel-body {
      @apply tw-w-full;
      @apply tw-flex;
      @apply tw-flex-row;
      @apply tw-py-0; }
    #user-profile-form #edit-mimemail .control-label {
      @apply tw-flex;
      @apply tw-flex-row;
      @apply tw-items-center;
      @apply tw-pl-0; }
      #user-profile-form #edit-mimemail .control-label input {
        width: 20px;
        height: 20px; }
        #user-profile-form #edit-mimemail .control-label input[type='checkbox'] {
          position: relative;
          margin-top: 0;
          margin-left: 0;
          margin-right: 1rem; }
  #user-profile-form #edit-timezone {
    @apply tw-hidden; }

/*------------------------------------*\
    Page: warranty-registration-form
\*------------------------------------*/
.warranty-registration-form {
  /*
  ======================================================
  : Page Content
  ======================================================
  */
  /*
  ======================================================
  : Webform
  ======================================================
  */ }
  .warranty-registration-form .region-content {
    position: relative;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .warranty-registration-form .region-content > .block-system {
      width: 100%; }
    .warranty-registration-form .region-content footer {
      display: none; }
  .warranty-registration-form .webform-client-form .webform-component--dura-seal-header,
  .warranty-registration-form .webform-client-form .webform-component--leather-guard-header {
    position: relative;
    width: 100%;
    padding-left: 50px;
    align-items: center;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    text-align: left; }
    .warranty-registration-form .webform-client-form .webform-component--dura-seal-header::before,
    .warranty-registration-form .webform-client-form .webform-component--leather-guard-header::before {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 50px;
      height: 50px;
      font-size: 50px;
      line-height: 50px;
      color: #4d87ca;
      content: "";
      font-family: 'FontAwesome'; }
  .warranty-registration-form .webform-client-form .webform-component h2 {
    @apply tw-text-5xl;
    margin-bottom: 0.8rem; }
  .warranty-registration-form .webform-client-form .form-actions button {
    @apply tw-text-3xl; }
  .warranty-registration-form .webform-client-form .panel-body::before, .warranty-registration-form .webform-client-form .panel-body:after {
    display: none; }
  @media screen and (min-width: 767px) {
    .warranty-registration-form .webform-client-form .panel-body {
      display: grid;
      grid-gap: 2.5rem;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      align-items: start; }
      .warranty-registration-form .webform-client-form .panel-body > .form-item:first-child {
        grid-column-start: 1; } }
  .warranty-registration-form .webform-client-form .panel {
    background-color: transparent;
    border: none;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .warranty-registration-form .webform-client-form .panel-heading {
    background-color: transparent;
    padding: 0 0 2.5rem 0;
    border-bottom: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .warranty-registration-form .webform-client-form .panel-heading .panel-title {
      @apply tw-text-4xl;
      font-weight: 800; }
  .warranty-registration-form .webform-client-form .panel-body {
    padding: 0; }
  .warranty-registration-form .webform-client-form .webform-component--vehicle-details h2 {
    @apply tw-text-4xl;
    font-weight: 800; }

/*------------------------------------*\
    Page: warranty-registrations
\*------------------------------------*/
.view-webform-results-by-user {
  position: relative;
  width: 100%;
  padding: 2rem;
  margin-bottom: 4rem;
  border: 1px solid #ddd;
  border-radius: 3px; }
  .view-webform-results-by-user .view-filters {
    margin-bottom: 2rem; }
  .view-webform-results-by-user table {
    font-size: 1.4rem; }
    .view-webform-results-by-user table thead tr th {
      background-color: #f5f5f5; }
      .view-webform-results-by-user table thead tr th.views-field-submitted {
        width: 20%; }

/*------------------------------------*\
    Page: Thank You
\*------------------------------------*/
.thank-you .node-page {
  position: relative;
  padding: 4rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8em; }
