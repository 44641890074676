/*------------------------------------*\
    Components: alert
\*------------------------------------*/
.alert {
  position: relative;
  background: $color-primary;
  border-radius: 4px;
  padding: 1rem;
  color: #fff;
  font-size: 300;
  display: flex;
  align-items: center;
  margin: 1rem 0;

  a.close {
    position: absolute;
    right: 1rem;
    font-size: 2rem;
    transition: all 0.3s;

    &:hover {
      color: #000;
    }
  }

  &.alert-success {
    background-color: $color-success;
  }
  &.alert-danger {
    background-color: $color-danger;
  }
  &.alert-warning {
    background-color: $color-warning;
  }
}
