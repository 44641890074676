/*------------------------------------*\
    Component: Mobile Menu
\*------------------------------------*/
#mmenu_left {
  .block-header-logo {
    display: none;
  }

  .mm-list {
    li {
      border: none;
    }
    span,
    a {
      padding: 0;
    }
  }

  ul.mm-panel {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .user-bar {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;

    ul {
      width: 100%;
      display: flex;
      justify-content: space-around;

      &.user-login {
        justify-content: center;
        li {
          margin-right: 1rem;
        }
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 1rem;
      }
      a {
        display: flex;
      }
    }
  }

  .block-header-search {
    @include searchbar;
    input {
      width: 100% !important;
    }
  }

  .block-uc-cart {
    margin: 2rem 0;
    .cart-block-title-bar,
    p.cart-block-items {
      display: none;
    }
  }

  .top-banner-menu {
    li {
      border-bottom: 1px solid #25282f;
      border-top: 1px solid #444141;
      a {
        padding: 1rem 0;
      }
    }
  }
}
