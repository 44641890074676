/*------------------------------------*\
    Component: Mobile Header
\*------------------------------------*/
.mobile_header {
  display: none;
  width: 100vw;
  height: 7rem;
  padding: 1rem;
  position: fixed;
  top: 0;
  z-index: 99;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 4px #eee;

  @include less-than(980px) {
    display: flex;
  }

  img {
    margin: 1rem;
    height: 3rem;
  }

  .hamburger-menu {
    &:focus {
      outline: none;
    }
  }
}
