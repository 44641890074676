/*------------------------------------*\
    Typography
\*------------------------------------*/

html {
  font-family: Arial, Helvetica, sans-serif;
}

body {
  font-family: $font-header;
  font-size: 1.6rem;

  h1{
    @apply tw-text-6xl;
    font-weight: 800;
  }
  
  h2{
    @apply tw-text-5xl;
    font-weight: 800;
  }
  
  h3{
    @apply tw-text-4xl;
    font-weight: 800;
  }
  
  h4{
    @apply tw-text-3xl;
    font-weight: 800;
  }

}



p {
  font-size: 1.6rem;
  line-height: 1.5;
}
